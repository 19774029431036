import React, { Component } from 'react'
import ayokayLogo from '../img/ayokay-logo-small.png';

export class Footer extends Component {
  render() {
    return (
      <div style={{minHeight: '100px', backgroundColor: '#323c43', display: 'flex', alignItems: 'center'}}>
       <img src={ayokayLogo} class="mx-5" style={{height: '100%'}} alt="Ayokay"/>
      </div>
    )
  }
}

export default Footer
